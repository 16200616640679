//import VueQr from "vue-qr";
export default {
  //components: { VueQr },
  data() {
    return {
      url: "",
      urls: "",
      op: "",
      code: "",
      scode: "",
      urlsCode: "",
      //redirect_uri:"",
      state: "",
      wechat: {}
    };
  },

  created() {
    this.urlsCode = window.location.href;
    localStorage.removeItem("usCookie");
    localStorage.removeItem("uskey");
    this.state = this.$route.query.state ? this.$route.query.state : "/";
  },

  mounted() {
    this.wechatAppid();
    /*
    if (this.$sopen.isWechat() == true) {
      this.wechatAppid();
    }
    */
  },

  methods: {
    goWechat() {
      window.location.href = this.url;
    },

    wechatAppid() {
      let options = {
        module: "wechat",
        title: "appid",
        data: {
          //appid: this.$route.query.schoolid,
          appid: "1008"
        }
      };
      let that = this;
      this.$sopen.requestUscenterApi(options).then(res => {
        let wechat = res.data.wechat;
        that.wechat = wechat;
        let redirect_uri = encodeURIComponent(window.location.protocol + "//" + window.location.host + "/weChat/oauth2?OP=" + wechat.scode);
        let url = "https://open.weixin.qq.com/connect/oauth2/authorize?appid=" + wechat.appid + "&redirect_uri=" + redirect_uri + //"&response_type=code&scope=snsapi_base&state=" +
        "&response_type=code&scope=snsapi_userinfo&state=" + encodeURIComponent(that.state) + "#wechat_redirect";
        that.url = url;
        window.location.href = url;
      });
    },

    uscode() {
      var data = {
        module: "wechat",
        title: "uscode",
        data: {
          appid: this.op,
          code: this.code
        }
      };
      let that = this;
      this.$sopen.requestApi(data).then(res => {
        that.scode = res;
      });
    }

  }
};