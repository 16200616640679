import { VApp } from 'vuetify/lib/components/VApp';
import { VContainer } from 'vuetify/lib/components/VGrid';

var render = function render() {
  var _vm = this,
      _c = _vm._self._c;

  return _c(VApp, [_c(VContainer, {
    attrs: {
      "grid-list-xs": ""
    }
  }, [_c('h3', [_vm._v("正在通过微信公众号验证")])])], 1);
};

var staticRenderFns = [];
export { render, staticRenderFns };